import domReady from '@roots/sage/client/dom-ready';
import $ from 'jquery';
import 'slick-carousel';
// import '../libs/jquery.lightbox.js';
/**
 * Application entrypoint
 */
domReady(async () => {
  let initSlider = () => {

    $('.block-persons-list__stage').each(function() {
      let $this = $(this)
      let $slider = $this.find('.block-persons-list__slider')

      let slickOptions = {
        focusOnSelect: false,
        autoplay: false,
        infinite: false,
        dots: true,
        arrows: false,
        mobileFirst: true,
        // cssEase: 'linear',
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 1500,

        responsive: [
          // {
          //   breakpoint: 600,
          //   settings: {
          //     slidesToShow: 2,
          //     centerMode: false,
          //     slidesToScroll: 1,
          //   }
          // },

        {
            breakpoint: 900,
            settings: {
              slidesToShow: 3,
              centerMode: false,
            }
          },

          {
            breakpoint: 1050,
            settings: {
              slidesToShow: 3,
              centerMode: false,
            }
          },
		  
          {
            breakpoint: 1300,
            settings: {
              slidesToShow: 5,
              centerMode: false,
            }
          },
        ]

      }

      $slider.slick(slickOptions)

      $slider.on('afterChange', function(event, slick, currentSlide, nextSlide){
        console.log(currentSlide, nextSlide)

        let newId = $slider.find('.slick-active').find('a').attr('href')

        setActiveSlideId(newId)
        // if (window.innerWidth <= 1050) {
        //   setActiveSlideId(newId)
        // }
      });
    })
  }

  let _timeout, lastId

  let setActiveSlideId = (newId) => {

    if (lastId === newId) return
        lastId = newId
      
        if (_timeout) clearTimeout(_timeout)

        _timeout= setTimeout(() => {
          $('.block-persons-more').fadeOut(() => {
            $('.block-persons-more__item').addClass('hidden')
            $(`.block-persons-more__item${newId}`).removeClass('hidden')
    
            $('.block-persons-more').fadeIn(() => {
            })
          })
        })

  }

  let setEvents = () => {
    $('body').on('click', '.block-persons-list__stage a', function(e) {
      e.preventDefault()
      
      let $this = $(this),
        href = $this.attr('href')

      if (lastId === href) return
      setActiveSlideId(href)
      // if (window.innerWidth > 1050) {
        
      // }
      // scrollTo({
      //   pos: $(href).offset().top
      // })

      // console.log('test', $this, href)
    })
  }

  let init = () => {
    initSlider()
    setEvents()
  }
  
  $(document).ready(() => {
    init()
  })
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
import.meta.webpackHot?.accept(console.error);
