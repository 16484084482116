import domReady from '@roots/sage/client/dom-ready';
import $ from 'jquery';
/**
 * Application entrypoint
 */
domReady(async () => {


  let makeMagic = () => {
    
    $('.wysiwyg').each(function() {
      let $this = $(this)

      $this.find(' > *').first().addClass('no-mt')
    })
    
    // $('.page-wysiwyg > *').first().addClass('no-mt')
  }

  let init = () => {
    makeMagic()
  }
  
  init()
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
import.meta.webpackHot?.accept(console.error);
