import domReady from '@roots/sage/client/dom-ready';
import $ from 'jquery';
/**
 * Application entrypoint
 */
domReady(async () => {


  let makeMagic = () => {
    // $('.single-ambasadors .menu-item-ambasadors').addClass('current-menu-item').addClass('current_page_item')
  }

  let init = () => {
    makeMagic()
  }
  
  init()
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
import.meta.webpackHot?.accept(console.error);
