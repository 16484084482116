
import $ from 'jquery';

export const setCookie = function(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = 'expires='+ d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

export const getCookie = function(cname) {
    var name = cname + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');

    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }

        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    
    return '';
}


export const scrollTo = function(obj) {
    let speed = 600

    if (obj.speed) {
        speed = obj.speed
    }
    
    if (obj) {
        $('html, body').animate({
            scrollTop: obj.pos - $('.header').height(),
        }, speed, function() {
            if (obj.callback) {
                obj.callback()
            }
        })
    }
}