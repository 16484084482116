import domReady from '@roots/sage/client/dom-ready';

import $ from 'jquery';

/**
 * Application entrypoint
 */
domReady(async () => {


  let makeMagic = () => {

	
	
  }

  let init = () => {
    makeMagic()
  }
  
  $(document).ready(() => {
    init()
  })
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
import.meta.webpackHot?.accept(console.error);
