import domReady from '@roots/sage/client/dom-ready';
import $ from 'jquery';
import './menu.js';
import './smooth-scroll.js';
import './wysiwyg.js';

import './blocks/block-hero.js';
import './blocks/block-persons-list.js';
import './blocks/block-register-form.js';

/**
 * Application entrypoint
 */
domReady(async () => {

  let $header = $('.header'), 
      $headerOverlay = $('.header__overlay'), 
      $headerMenuTrigger = $('.header__nav-trigger'),
      $headerNav = $('.header__nav');
  
  let toggleNav = () => {
    $headerMenuTrigger.toggleClass('active')
    $headerNav.toggleClass('active')
    $header.toggleClass('active')
  }

  let adjustPageHeaderHeight = () => {
    let $pageHeaderInner = $('.page-header')
    let $pageHeaderBg = $('.page-header__bg')
    // let $pageHeaderShadow = $('.page-header__bg__shadow')

    $pageHeaderBg.css('min-height', $pageHeaderInner.outerHeight() )
    // $pageHeaderShadow.css('min-height', $pageHeaderInner.outerHeight() )
  }
  
  let setEvents = () => {
    //

    $headerMenuTrigger.on('click', (e) => {
      e.preventDefault()
      toggleNav()
    })

    $headerOverlay.on('click', (e) => {
      e.preventDefault()
      toggleNav()
    })

    $headerNav.on('click', 'a', (e) => {

      console.log($(e.currentTarget).data())
      
      if ($(e.currentTarget).data('bypass') === '') {

      } else {
        e.preventDefault()

        toggleNav()
      }
    })

    $('[data-link]').on('click', function() {
      let $this = $(this);
      let navigateTo = $this.data('link')
      let target = $this.data('target')

      if (target) {
        window.open(navigateTo, target)
      } else {
        window.location = navigateTo;
      }
    })

    $(window).on('resize', () => {
      adjustPageHeaderHeight()
    })
  }
  
  let init = () => {
    setEvents()
    adjustPageHeaderHeight()

    $('.header__nav__social').html($('.footer .block-social').clone())
  }

  $(document).ready(() => {
    init();
    
  })
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
import.meta.webpackHot?.accept(console.error);
