import domReady from '@roots/sage/client/dom-ready';

import $ from 'jquery';

import Parsley from 'parsleyjs';
import { scrollTo } from '../utils/index.js';
/**
 * Application entrypoint
 */
domReady(async () => {

  let $form = $('.block-register-form form')

  Parsley.addMessages('pl', {
    defaultMessage: 'Wartość wygląda na nieprawidłową',
    type: {
      email: 'Wpisz poprawny adres e-mail',
      url: 'Wpisz poprawny adres URL',
      number: 'Wpisz poprawną liczbę',
      integer: 'Dozwolone są jedynie liczby całkowite',
      digits: 'Dozwolone są jedynie cyfry',
      alphanum: 'Dozwolone są jedynie znaki alfanumeryczne',
    },
    notblank: 'Pole nie może być puste',
    required: 'Pole jest wymagane',
    pattern: 'Wpisz numer telefonu w formacie 9-cyfrowym, np. 500-500-500',
    min: 'Wartość nie może być mniejsza od %s',
    max: 'Wartość nie może być większa od %s',
    range: 'Wartość powinna zaweriać się pomiędzy %s a %s',
    minlength: 'Wpisz telefon w formacie 123-456-789',
    maxlength: 'Maksymalna ilość znaków wynosi %s',
    length: 'Ilość znaków wynosi od %s do %s',
    mincheck: 'Wybierz minimalnie %s opcji',
    maxcheck: 'Wybierz maksymalnie %s opcji',
    check: 'Wybierz od %s do %s opcji',
    equalto: 'Wartości nie są identyczne',
  })

  Parsley.setLocale('pl')

  let submitForm = function (_$formInstance) {
    let $formInstance = _$formInstance,
      data = $formInstance.serialize(),
      id = $formInstance.attr('id'),
      url = $formInstance.attr('action'),
      $submitRow = $formInstance.find('.rfr-form__submit'),
      $buttonSubmit = $formInstance.find('.rfr-form__submit-btn'),
      $messageHolder = $formInstance.find('.rfr-form__messages'),
      $messageSuccess = $messageHolder.find('.rfr-form__messages__success'),
      $messageError = $messageHolder.find('.rfr-form__messages__error'),
      $preloader = $formInstance.find('.rfr-form__preloader'),
      successRedirect = $formInstance.data('successredirect')

    url = url.replace('submit_', '')

    $.ajax({
      url: url ? url : '/',
      type: 'POST',
      cache: false,
      data: data,
      beforeSend: function () {
        
        $submitRow.addClass('hidden')
        $buttonSubmit.attr('disabled', 'disabled')
        $preloader.removeClass('hidden')
        $messageHolder.addClass('hidden')
        $messageSuccess.addClass('hidden')
        $messageError.addClass('hidden')
      },
      success: function (_res) {
        let res = JSON.parse(_res)

        if (res.status) {
          $formInstance.get(0).reset()

          $buttonSubmit.fadeOut(function () {

            if (successRedirect && successRedirect !== '') {
              window.location = successRedirect
            } else {
              $messageHolder.removeClass('hidden')
              $messageSuccess.removeClass('hidden')
              $preloader.addClass('hidden')
              $messageError.addClass('hidden')


              scrollTo({
                pos: $messageSuccess.offset().top - 40,
              })
            }
          })
        } else {
          $submitRow.removeClass('hidden')
          $buttonSubmit.attr('disabled', false)
          $buttonSubmit.removeClass('hidden')
          $preloader.addClass('hidden')
          $messageHolder.removeClass('hidden')
          $messageError.removeClass('hidden')
          

          
          scrollTo({
            pos: $messageError.offset().top - 40,
          })
        }
      },
      error: function () {
        $submitRow.removeClass('hidden')
        $buttonSubmit.attr('disabled', false)
        $preloader.removeClass('hidden')
        $messageHolder.removeClass('hidden')
        $messageError.removeClass('hidden')

        let pos = $messageError.offset().top - 40

        console.log(pos)
        
        scrollTo({
          pos
        })

      },
    })
  }

  let checkCheckboxIsChecked = ($form) => {
    let isCheckedJustOne = false
    
    // $form.find('input:checkbox').each((index, item) => {
    //   let isChecked = $(item).get(0).checked

    //   if (isChecked  !isCheckedJustOne) {
    //     isCheckedJustOne = true
    //   }
    // })

    // $form.data('proceed', isCheckedJustOne)

    // if (isCheckedJustOne) {
    //   $('.rfr-form__checkbox-message').addClass('hidden')
    // } else {
    //   $('.rfr-form__checkbox-message').removeClass('hidden')
    // }
  }

  let setEvents = function () {

    $form.on('click', '.rfr-form__submit-btn', function($event) {
      $event.preventDefault()
    })
    
    $form.on('submit', function (event) {
      event.preventDefault()

      let $this = $(this),
        formParsley = $this.parsley()

      formParsley.destroy()

      checkCheckboxIsChecked($form)

      if (formParsley.validate()) {
        submitForm($this)
      }
    })

    $form.on('click', '.rfr-form__submit', (e) => {
			e.preventDefault();
			
      checkCheckboxIsChecked($form)

      if ($form.data()) {
        $form.submit()
      }
		})

    $form.on('change', 'input:checkbox', () => {
      checkCheckboxIsChecked($form)
    })
  }

  let init = function () {
    setEvents()
  }

  $(document).ready(function () {
    init()
  });
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */

import.meta.webpackHot?.accept(console.error);
