import domReady from '@roots/sage/client/dom-ready';
import $ from 'jquery';
/**
 * Application entrypoint
 */
domReady(async () => {


  let makeMagic = () => {
	$('a[href*="#"]').not('[data-modal]').not('[data-video]').not('[data-custom]').not('[href="#"]').not('[href="#0"]').not('').click(function(event) {
		let isSameHostname = location.hostname == this.hostname,
			isSamePathname = location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 

		if (isSamePathname && isSameHostname) {
			let target = $(this.hash)
			let hash = this.hash

			target = target.length ? target : $('[name=' + hash.slice(1) + ']');

			if (target.length) {
				event.preventDefault();
				let headerHeight = window.innerWidth >= 768 ? $('.header').height() : $('.header__top').height()
				let offsetTop = target.offset().top - headerHeight

				$('html, body').animate({
					scrollTop: offsetTop,
				}, 800)
			}
		}
	})
  }

  let init = () => {
    makeMagic()
  }
  
  init()
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
import.meta.webpackHot?.accept(console.error);
